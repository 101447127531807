.card {
    width: 100%;
    position: absolute;
    padding-top: 100%;
    color: #000;
    border-radius: 32px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    transition: all 1s;
    /* box-shadow: 0px 0px 24px 20px rgba(0,0,0,0); */
    /* filter: blur(5px);
    transform: scale(0.9); */
}

.cardActive {
    /* box-shadow: 0px 0px 24px 20px rgba(0,0,0,0.20); */
    /* filter: none;
    transform: scale(1); */
}

.cardEmpty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #C047BC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 0;
}

.cardEmptyInfo {
    position: relative;
    color: #fff;
    padding: 32px;
}

.cardEmptyTitle {
    text-align: center;
    font-weight: normal;
    font-size: 18px;
}

.cardEmptyBio {
    margin-top: 4px;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
}

.cardWrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.cardList {
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.cardInfo {
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.7) 50%);
    color: #fff;
    padding: 32px 32px 48px;
}

.cardInfoTitle {
    font-weight: 700;
    font-size: 18px;
}

.cardInfoBio {
    margin-top: 4px;
    font-weight: normal;
    font-size: 16px;
}

.cardInfoText {
    color: #ccc;
}

.cardLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 0;
}

.cardLoadingIcon {
    display: flex;
    animation: spin 2s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

.swipe {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 32px;
    position: absolute;
    bottom: -28px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}

.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    height: 56px;
    width: 56px;
    border-radius: 50%;
}

.btnSkip {
    background-color: #fff;
    color: #C047BC;
}

.btnMatch {
    background-color: #C047BC;
    color: #fff
}

.btnSuperLike {
    background-color: #ff0045;
    color: #fff
}

.icon {
    font-size: 24px;
}