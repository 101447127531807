.PageBuyCoins {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    background: #000;
}

.PageBuyCoins-header {
    position: relative;
}

.PageBuyCoins-header:before {
    content: '';
    position: absolute;
    bottom: -16px;
    top: -16px;
    left: -50%;
    width: 200%;
    background: linear-gradient(180deg, #C047BC, rgba(0, 0, 0, 1));
    border-bottom-left-radius: 400%;
    border-bottom-right-radius: 400%;
}

.PageBuyCoinsTitleWrapper {
    color: #fff;
    font-size: 24px;
    line-height: 2em;
    font-weight: 500;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.PageBuyCoins-list {
    color: #fff;
    list-style: none;
    margin: 20px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border: 1px solid #C047BC;
    padding: 24px;
    border-radius: 24px;
}

.PageBuyCoins-listItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    font-size: 18px;
}

.PageBuyCoins-listItemIcon {
    font-size: 22px;
}

.PageBuyCoinsTitle {
    font-size: 44px;
    line-height: 56px;
    text-transform: uppercase;
    text-align: left;
}

.PageBuyCoins-purchases {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 24px 0;
    width: 100%;
}

.PageBuyCoins-plan {
    border: 1px solid #C047BC;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.PageBuyCoins-plan .sr-only,
.PageBuyCoins-plan--active .sr-only {
    visibility: hidden;
    position: absolute;
}

.PageBuyCoins-plan--active {
    background-color: #C047BC;
}

.PageBuyCoins-wallets {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 20px;
    line-height: 2em;
    font-weight: 500;
    margin: 16px 0;
}

.PageBuyCoins-plan-coins {
    font-size: 24px;
    font-weight: 200;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-left: 0;
    margin-right: auto;
}

.PageBuyCoins-plan-coins:before {
    content: '';
    height: 32px;
    width: 32px;
    display: flex;
    background-image: url(../../assets/images/heart-sm.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}

.PageBuyCoins-plan-tons {
    font-weight: 600;
    font-size: 18px;
}

.PageBuyCoins-submit {
    position: relative;
    border: none;
    background-color: #C047BC;
    /* background-color: transparent; */
    border: 1px solid #C047BC;
    border-radius: 32px;
    text-decoration: none;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 16px;
    width: 100%;
}

.PageBuyCoins-submit:disabled {
    opacity: 0.3;
}