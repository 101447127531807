.Button {
    padding: 22px 20px;
    text-align: center;
    border-radius: 32px;
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    border: 0;
    background-color: rgba(255,255,255,0.12);
    transition: background-color ease;
    transition-duration: .3s;
}

.ButtonAccent {
    background-color: #C047BC;
}