.PageGroupDetails {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 16px;
  padding-left: var(--safe-area-left);
  padding-right: var(--safe-area-right);
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg, #C047BC, rgba(0,0,0,1));
}

/* Card */
.PageGroupDetailsCard {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  text-align: center;
}

.PageGroupDetailsCardAvatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-color: #ffffff;
  /* border: 1px solid rgba(255,255,255,0.2); */
  margin-bottom: 24px;
}

.PageGroupDetailsCardTitle {
  font-size: 32px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 16px;
}

.PageGroupDetailsCardTitle svg {
  margin-left: 8px;
}

.PageGroupDetailsCardDescription {
  white-space: break-spaces;
  margin-bottom: 24px;
}

/* Info */

.PageGroupDetailsInfo {
  background-color: rgba(255,255,255, 0.08);
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
}

.PageGroupDetailsInfoIndicators {
  display: flex;
  flex-direction: row;
}

.PageGroupDetailsInfoActionsButton {
  display: block;
  width: 100%;
}

.PageGroupDetailsInfoActionsButton + .PageGroupDetailsInfoActionsButton {
  margin-top: 16px;
}

/* Leaderboard */

.PageGroupDetailsLeaderboard {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
}
