.QR {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.QRtext {
    color: #fff;
    text-align: center;
    font-size: 32px;
    margin-bottom: 24px;
    line-height: 40px;
    font-weight: 700;
}
.QRImage {
    width: 300px;
    height: 300px;
    background-image: url('https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/qr.jpg');
    background-color: rgba(255, 255, 255, 1);
    background-size: 90%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 32px;
}