.special {
    color: #FF9CFC;
    font-weight: 600;
    display: block;
}

.Step1, .Step3 {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
}

.Step2 {
    position: relative;
}

.Step2 > .StepPhotoNote {
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    font-weight: 300;
    font-size: 14px;
}

.StepForm {
    text-align: center;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.StepForm .form {
    margin-top: 8px;
}

.StepFormGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.StepFormGroupTextarea {
    margin-top: 8px;
}

.StepFormLabel {
    color: #fff;
    font-size: 16px;
}

.StepFormInput {
    border: none;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.1);
}

.StepFormTextarea {
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
}

.StepFormInputWrapper {
    flex: 1 0 50%;
}

.StepFormSexGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1 0 50%;
}

.StepFormSex {
    margin: 0;
}

.StepFormSexLabel {
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 14px 0;
    position: relative;
    color: #fff;
}

.StepFormSexInput {
    visibility: hidden;
    width: 0;
    height: 0;
    display: flex;
    margin: 0;
}

.StepFormSexTitle {
    position: relative;
    z-index: 10;
}

#male + .StepFormSexLabel {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

#female + .StepFormSexLabel {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

#male + .StepFormSexLabel:before {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    bottom: 0;
    background-color: #FF9CFC;
    color: #000;
    display: block;
    z-index: 1;
    transition: all 0.3s;
    border-radius: 12px;
}

#male:checked + .StepFormSexLabel:before {
    left: 0;
}

.StepFormLabel {
    color: #fff;
    text-align: left;
    flex: 1 0 50%;
}

.StepFormSexInput:checked + .StepFormSexLabel {
    color: #000;
}

.StepTitle {
    font-family: 'Poppins', serif;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: #fff;
}

.StepSubtitle {
    font-family: 'Poppins', serif;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    font-weight: 300;
}

.Progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 16px;
}

.ProgressItem {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    transition: all 0.5s;
    background-color: #FF9CFC;
    display: flex;
}

.ProgressItemActive {
    width: 32px;
}