.range-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 1.6rem;
    height: calc(16px + 1.6rem);
    width: 100%;
}
.range-input-wrapper {
    width: calc(100% + 16px);
    margin: 0 calc(16px / -2);
    position: absolute;
    height: 16px;
}
.range-control-wrapper {
    width: 100%;
    position: absolute;
    height: 16px;
}
.range-input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;
}
.range-input::-ms-track {
    appearance: none;
    background: transparent;
    border: transparent;
}
.range-input::-moz-range-track {
    appearance: none;
    background: transparent;
    border: transparent;
}
.range-input:focus::-webkit-slider-runnable-track {
    appearance: none;
    background: transparent;
    border: transparent;
}
.range-input::-ms-thumb {
    appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    border-radius: 0px;
    border: 0 none;
    cursor: grab;
    background-color: red;
}
.range-input::-ms-thumb:active {
    cursor: grabbing;
}
.range-input::-moz-range-thumb {
    appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    border-radius: 0px;
    border: 0 none;
    cursor: grab;
    background-color: red;
}
.range-input::-moz-range-thumb:active {
    cursor: grabbing;
}
.range-input::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    border-radius: 0px;
    border: 0 none;
    cursor: grab;
    background-color: red;
}
.range-input::-webkit-slider-thumb:active {
    cursor: grabbing;
}
.range-rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    border-radius: 3px;
    background: lightgrey;
}
.range-inner-rail {
    position: absolute;
    height: 100%;
    background: hotpink;
    opacity: 0.5;
}
.range-control {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    background: hotpink;
    top: 50%;
    margin-left: calc(16px / -2);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
}
