.PageBalance {
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 16px;
    padding-bottom: 20px;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 1;
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
    background: #000;
}

.PageBalance:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    background: linear-gradient(180deg, #C047BC, rgba(0, 0, 0, 1));
    border-bottom-left-radius: 400%;
    border-bottom-right-radius: 400%;
    height: 60vh;
}

.PageBalanceFooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 112px;
    padding: 0 16px;
}

.PageBalanceFooterLink {
    position: relative;
    border: none;
    background-color: #C047BC;
    border: 1px solid #C047BC;
    border-radius: 32px;
    text-decoration: none;
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    gap: 16px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}

.PageBalanceFooterLink span {
    letter-spacing: 1.2px;
}