.PageMatches {
    background: #000;
}

.PageMatchesList {
    padding: 0 24px 128px;
}

.iconTakePlace {
    font-size: 20px;
    color: #FF9CFC;
}

.PageMatchesHeader {
    position: relative;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PageMatchesHeader:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -50%;
    width: 200%;
    background: linear-gradient(180deg, #C047BC, rgba(0,0,0,1));
    border-bottom-left-radius: 400%;
    border-bottom-right-radius: 400%;
    height: 100vh;
}

.PageMatchesTitleWrapper {
    color: #fff;
    font-size: 24px;
    line-height: 2em;
    font-weight: 500;
    position: relative;
    text-align: center;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.PageMatchesTitle {
    margin: 0 16px;
}

.PageMatchesSubtitle {
    color: #eee;
    font-size: 18px;
    line-height: 1.6em;
    font-weight: 100;
    position: relative;
    text-align: center;
    padding: 0 24px;
}

.splash {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
}

.splashWrapper {
    height: 140px;
    width: 180px;
    margin: 0 auto;
    position: relative;
}

.splash .heart-loader {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    margin-left: -80px;
    margin-top: -51px;
    width: 160px;
    height: 102px;
    overflow: visible;
}

.splash .heart-loader-group {
    transform-origin: 0 90px;
}

.splash .heart-left-loader,
.splash .heart-right-loader {
    stroke: #FF9CFC;
    stroke-dasharray: 308.522, 308.522;
    stroke-dashoffset: 308.522;
}

.splash .heart-left-loader {
    animation: splash-left-heart-anim 5s 0s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    transform: translateX(0);
}

.splash .heart-right-loader {
    animation: splash-right-heart-anim 5s 0s alternate;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    transform: translate(62px, 12px);
}

.splash .heart-both-loader {
    stroke: #A1369E;
    fill: transparent;
    stroke-dasharray: 308.522, 308.522;
    stroke-dashoffset: 308.522;
    animation: splash-heart-anim 5s 0s alternate;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@-webkit-keyframes splash-left-heart-anim {
    12% {
        stroke-dashoffset: 188.522;
        stroke: #FF9CFC;
        fill: transparent;
    }
    31% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    41% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    43% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    85% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 0;
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        stroke: #FF7E7E;
        fill: #FF7E7E;
        opacity: 1;
    }
}

@keyframes splash-left-heart-anim {
    12% {
        stroke-dashoffset: 188.522;
        stroke: #FF9CFC;
        fill: transparent;
    }
    31% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        transform: translateX(0);
    }
    41% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        transform: translateX(0);
    }
    43% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        transform: translateX(0);
        opacity: 1;
    }
    85% {
        stroke-dashoffset: 0;
        fill: transparent;
        stroke: #FF9CFC;
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 0;
        transform: translateY(-2px);
        stroke: #FF82FB;
        fill: #FF82FB;
        opacity: 1;
    }
}

@keyframes splash-right-heart-anim {
    12% {
        stroke-dashoffset: 188.522;
        fill: transparent;
        stroke: #FF9CFC;
    }
    31% {
        stroke-dashoffset: 0;
        transform: translate(62px, 12px);
        fill: transparent;
        stroke: #FF9CFC;
    }
    41% {
        stroke-dashoffset: 0;
        transform: translate(62px, 12px);
        fill: transparent;
        stroke: #FF9CFC;
    }
    43% {
        stroke-dashoffset: 0;
        transform: translate(62px, 12px);
        opacity: 1;
        fill: transparent;
        stroke: #FF9CFC;
    }
    85% {
        stroke-dashoffset: 0;
        transform: translate(62px, 12px);
        opacity: 0;
        fill: transparent;
        stroke: #FF9CFC;
    }
    100% {
        stroke-dashoffset: 0;
        transform: translate(62px, 10px);
        stroke: #C047BC;
        fill: #C047BC;
        opacity: 1;
    }
}

@keyframes splash-heart-anim {
    55% {
        stroke-dashoffset: 308.522;
        fill: transparent;
    }
    70% {
        stroke-dashoffset: 0;
        fill: transparent;
    }
    87% {
        stroke-dashoffset: 0;
        fill: #A1369E;
    }
    100% {
        stroke-dashoffset: 0;
        fill: #A1369E;
    }
}