.ScoreBar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 176px;
    padding: 0 16px 0;
}

.ScoreBarInfo {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.ScoreBarInfoCurrent {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -.078px;
    text-shadow: 1px 1px 2px rgba(181, 103, 12, .25);
    margin-bottom: 5px
}

.ScoreBarInfoLimit {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-left: 4px;
    letter-spacing: -.078px
}

.ScoreBarInfoLimit span {
    font-size: 16px;
    font-weight: 400;
}

.ScoreBarInfoProgress {
    width: 100%;
    padding: 4px 8px 4px;
    flex: 1 0 100%;
    margin-top: 8px;
}

.ScoreBarInfoIcon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
}

.ProgressBar {
    border-radius: 8px;
    background: rgba(255, 255, 255, .2);
    width: 100%;
    height: 16px;
    overflow: hidden
}

.ProgressBarFill {
    position: relative;
    display: block;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(270deg, #c047bc, #ffffff);
    transition: all 0.3s;
}

.ProgressBarFill:before {
    content: attr(data-seconds);
    position: absolute;
    top: 0;
    right: 5px;
    min-width: 25px;
    color: #fff;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-shadow: 1px 1px 2px rgba(181, 103, 12, .25);
    letter-spacing: -.078px;
}

.ScoreBarButtons {
    border-radius: 33px;
    background: rgba(255, 255, 255, .12);
    width: 100%;
    display: flex;
    overflow: hidden
}

.ScoreBarButton {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;
    min-width: 25%;
    position: relative;
    user-select: none;
    transition: background-color ease;
    transition-duration: .3s;
    cursor: pointer;
    text-decoration: none;
}

.ScoreBarButton:active {
    transition-duration: 0s;
    background-color: #e5e3e352
}

.ScoreBarButton:after {
    content: "";
    height: 34px;
    width: 1px;
    background: rgba(255, 255, 255, .16);
    position: absolute;
    right: 0
}

.ScoreBarButton:last-child:after {
    display: none
}

.ScoreBarButtonIcon {
    height: 24px;
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: 590;
    line-height: 24px;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(181, 103, 12, .25), .5px .5px 0px rgba(0, 0, 0, .04);
    position: relative
}

.ScoreBarButtonText {
    font-size: 12px;
    font-weight: 510;
    line-height: 12px;
    color: #fff;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, .08)
}

.iconRed {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    right: -2px;
    top: -4px
}

.iconRed:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #ff522c;
    border-radius: 50%;
    right: 3px;
    top: 3px
}

.iconFrens {

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 27px;
    height: 27px
}

.iconFrensDragon {

}

.iconCoin {
    width: 30px;
    height: 30px;
    background-position: 3px 0;
    border-radius: 50%;
    background-size: 24px;
    background-repeat: no-repeat;
    /* background-image: url() */
}