.ProfileFilters {
    position: relative;
}

.FiltersTitle {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 20px;
    padding: 8px 16px;
}

.FiltersBlock {
    background-color: rgba(255,255,255, 0.1);
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 24px;
}

.FiltersRange {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.FiltersRange-title {
    flex: 1 0 70%;
}

.FiltersRange-value {
    flex: 1 0 30%;
    text-align: right;
}

.FiltersPhoto {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.FiltersPhoto-checkarea {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 1px solid #fff;
    border-radius: 24px;
}

.FiltersPhoto-checkareaItem {
    flex: 1 0;
    text-align: center;
    font-size: 14px;
    padding: 4px 12px;
}

.FiltersPhoto-checkareaItem--active {
    background-color: #fff;
    color: #C047BC;
    border-radius: 24px;
}