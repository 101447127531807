.input {
    display: inline-flex; 
    padding-top: 12px;
    padding-bottom: 12px; 
    padding-left: 24px;
    padding-right: 24px; 
    border-radius: 12px; 
    border-width: 1px; 
    border-style: solid; 
    width: 100%; 
    line-height: 1; 
    appearance: none; 
    background-color: transparent;
    color: #fff;
}

.input:focus {
    outline-color: #C047BC;
}

.input--default {
    border-color: #F3F4F6;
}

.input--default:hover {
    border-color: #E5E7EB; 
}

.input--with-postfix {
    padding-right: 40px;
}

.input--with-pretfix {
    padding-left: 40px;
}

.input-postfix {
    display: flex; 
    position: absolute; 
    right: 0; 
    top: 50%; 
    padding-left: 0.25rem; 
    align-items: center; 
    width: 2.5rem; 
}

.input-prefix {
    display: flex; 
    position: absolute; 
    left: 0; 
    top: 50%; 
    padding-left: 1.5rem; 
    align-items: center; 
    width: 2.5rem; 
    color: #E5E7EB; 
}

.input-error {
    position: absolute; 
    margin-top: 0.25rem; 
    font-size: 0.875rem;
    line-height: 1.25rem; 
}

.input-note {
    margin-top: 0.25rem;
    text-align: right;
    color: #ccc;
    text-align: right;
    font-size: 14px;
}