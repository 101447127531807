.Navigation {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 24px;
    padding: 0 16px;
}

.NavigationButtons {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 12px;
    background-color: #C047BC;
    border-radius: 48px;
    padding: 0 16px;
}

.NavigationButton {
    border-radius: 32px;
    padding: 12px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
    transition: background-color ease;
    transition-duration: .3s;
    cursor: pointer;
    text-decoration: none;
    gap: 2px;
}

.NavigationButtonIcon {
    height: 24px;
    width: 24px;
    text-align: center;
    position: relative;
    color: #fff;
}

.NavigationButtonText {
    font-size: 12px;
    line-height: 20px;
    color: #fff;
}

.NavigationButtonActive .NavigationButtonIcon {
    color: #ff82fb;
}

.NavigationButtonActive .NavigationButtonText {
    color: #ff82fb;
}

