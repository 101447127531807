.radio {
    margin-bottom: 12px; 
    align-items: center;
    width: 100%;
}

.radioLabel {
    font-size: 14px;
    line-height: 20px; 
    margin-bottom: 8px;
}

@media (min-width: 640px) { 
    .radio {
        display: flex; 
        margin-left: 1.5rem; 
    }
}