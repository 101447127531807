.GroupUsersList {
    position: relative;
    border-radius: 16px;
    background: rgba(255, 255, 255, .12);
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
}

.GroupUsersListItem {
    -webkit-user-select: none;
    user-select: none;
    transition: background-color ease;
    transition-duration: .3s;
    cursor: pointer;
    margin-left: -16px;
}

.GroupUsersListItem:last-child {
    margin-bottom: 0;
}

.GroupUsersListItemInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    align-items: center;    
}

.GroupUsersListItemImage {
    background: #fff;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 48px;
    background-size: cover;
    border: 1px solid #000;
}

.GroupUsersListItemAvatar {
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.GroupUsersListItemContent {
    overflow: hidden;
    flex-grow: 1;
}

.GroupUsersListItemContentTitle {
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    font-weight: 510;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 12px;
    overflow: hidden;
    margin-bottom: 2px;
}

.GroupUsersListItemContentScore {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #ffffffbf;
    gap: 4px;
}

.GroupUsersListItemContentReward {
    position: relative;
    padding-left: 4px;
    font-weight: 600;
    font-family: var(--font-display-ios-rounded);
}

.GroupUsersListItemInviteReward{
    margin-left: auto;
    color: var(--notcoin-orange);
    font-family: var(--font-display-ios-rounded);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}