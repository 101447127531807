.PageFriends {
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 16px;
    padding-bottom: 128px;
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 1;
}

.PageFriendsInner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
}

.PageFriendsImage {
    font-size: 72px;
    text-align: center;
}

.PageFriendsCount {
    font-size: 32px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 32px;
    text-align: center;
}

.PageFriendsInviteButton {
    margin-top: 24px;
}

/* InviteInfo */

.PageFriendsInviteInfoTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: var(--harmony-amor-accent);
    padding-bottom: 12px;
}

.PageFriendsInviteInfoContent {
    background-color: #ffffff1f;
    padding: 0 16px;
    border-radius: 16px;
}

.PageFriendsInviteInfoBlock {
    display: flex;
    padding: 16px 0;
}

.PageFriendsInviteInfoBlockImage {
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.PageFriendsInviteInfoBlockTitle {
    font-size: 17px;
    font-weight: 510;
    line-height: 22px;
    display: flex;
    margin-bottom: 2px;
}

.PageFriendsInviteInfoBlockDescription {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
}

.PageFriendsInviteInfoBlockTitle span,
.PageFriendsInviteInfoBlockDescription span {
    font-weight: 600;
    color: var(--harmony-amor-accent);
}

.PageFriendsInviteInfoBlockTitle span.tg,
.PageFriendsInviteInfoBlockDescription span.tg {
    color: #42a0ff;
}

.PageFriendsUserListTitle {
    padding: 16px 0 12px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

/* Score */

.PageFriendsScore {
    background-color: #ffffff1f;
    padding: 16px;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
}

.PageFriendsScoreValue {
    font-size: 20px;
    line-height: 20px;
}

.PageFriendsScoreLeaderboard span {
    margin-right: 8px;
}