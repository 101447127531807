.PageOnboarding {
    position: relative;
}

.PageOnboardingContent {
    padding: 32px;
    padding-bottom: 100px;
}

.PageOnboardingNext {
    border: none;
    box-shadow: none;
    background-color: #FF9CFC;
    font-family: 'Poppins', serif;
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 16px;
    text-transform: uppercase;
}

.PageOnboardingNext:disabled {
    background-color: rgba(255, 255, 255, 0.1);
}

.PageOnboardingImg {
    width: 75%;
    border-radius: 32px;
    margin: 32px auto 16px;
}

.PageOnboardingFooter {
    margin: 32px auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(253,187,45,0) 100%);
}