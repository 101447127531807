.PageMain {
    color: #fff;
    height: 100vh;
    padding-top: 16px;
    padding-bottom: 16px;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
    background: #000;
}


.PageMain:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    background: linear-gradient(180deg, #C047BC, rgba(0,0,0,1));
    border-bottom-left-radius: 400%;
    border-bottom-right-radius: 400%;
    height: 60vh;

}

.PageMainHeader {
    width: 100%;
    margin: 0 auto 16px;
    position: relative;
}

.PageMainHeaderJoinGroupButton {
    width: 100%;
    margin-bottom: 24px;
}

.PageMainHeaderJoinGroupButton svg{
    margin-left: 8px;
}

.PageMainFooter {
    width: 100%;
    margin: auto auto 0;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
}

.switch {
    width: 90%;
    margin: 0 auto 24px;
}

.MainGridBtn {
    padding-top: 64px;
    position: relative;
    background-color: #C047BC;
    border-radius: 32px;
}

.MainGridBtnContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.MainGridIcon {
    font-size: 32px;
    color: #fff;
}

.MainGridText {
    color: #fff;
    font-size: 14px;
}